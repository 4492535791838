var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import initSchemaLogger from '@wix/bi-logger-pay-button';
export var getBiLogger = function (biLoggerDefaults, webBiLogger) {
    var biLogger = initSchemaLogger(webBiLogger)();
    biLogger.util.updateDefaults(__assign({ sessionId: 'exampleStr' }, biLoggerDefaults));
    return biLogger;
};
