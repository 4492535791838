export var buttonStructure = {
    componentType: 'wysiwyg.viewer.components.SiteButton',
    style: {
        type: 'TopLevelStyle',
        styleType: 'custom',
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
        },
        style: {
            properties: {
                'alpha-bg': '1',
                'alpha-bgh': '1',
                'alpha-brd': '1',
                'alpha-brdh': '0',
                'alpha-txt': '1',
                'alpha-txth': '1',
                bg: 'color_15',
                bgh: 'color_18',
                'boxShadowToggleOn-shd': 'false',
                brd: 'color_15',
                brdh: 'color_11',
                brw: '0',
                fnt: 'font_6',
                rd: '0px',
                shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                txt: 'color_11',
                txth: 'color_11'
            },
            propertiesSource: {
                'alpha-bg': 'value',
                'alpha-bgh': 'value',
                'alpha-brd': 'value',
                'alpha-brdh': 'value',
                'alpha-txt': 'value',
                'alpha-txth': 'value',
                bg: 'theme',
                bgh: 'theme',
                'boxShadowToggleOn-shd': 'value',
                brd: 'theme',
                brdh: 'theme',
                brw: 'value',
                fnt: 'theme',
                rd: 'value',
                shd: 'value',
                txt: 'theme',
                txth: 'theme'
            },
            groups: {}
        },
        componentClassName: 'wysiwyg.viewer.components.SiteButton',
        skin: 'wysiwyg.viewer.skins.button.BasicButton',
        id: 'style-jktpvpis',
    },
    layout: {
        width: 142,
        height: 40,
    },
    type: 'Component',
    data: {
        label: 'Buy Now',
        type: 'LinkableButton',
        name: 'superPuperButton',
        id: 'button-buy',
        metaData: {
            isHidden: false,
            isPreset: true,
            schemaVersion: '1.0',
        },
    },
    props: {
        align: 'center',
        margin: 0,
        type: 'ButtonProperties',
        metaData: {
            isHidden: false,
            isPreset: true,
            schemaVersion: '1.0',
        },
    },
};
export var buttonRole = 'PayButton';
