export function tryToGetOrigin(wixCode) {
    var origin = '';
    try {
        origin = new URL(wixCode.location.baseUrl).origin;
    }
    catch (e) {
        // Can't get origin
    }
    return origin;
}
